<template>
    <div class="page-cart g-page">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item>我的购物车</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="my-cart">
                <div class="title">
                    <div class="tab">
                        <div class="item" :class="activeIndex === 0 ? 'active' : ''" @click="activeIndex = 0">我的购物车
                        </div>
                        <div class="item" :class="activeIndex === 1 ? 'active' : ''" @click="activeIndex = 1">我的积分筐
                        </div>
                    </div>
	                <img v-if="activeIndex === 0 && isInquiry" src="@/assets/img/kefu.png" alt="" style="width: 30px;height: 30px;cursor: pointer" @click="clickinquiry">
	                <!--                    <div class="g-btn small" v-if="activeIndex === 0 && isInquiry" @click="clickinquiry">询价</div>-->
<!--	                <div class="g-btn small" v-if="activeIndex === 0 && isInquiry" @click="clickinquiry">-->
<!--		                <img src="@/assets/img/kefu1.png" alt="" style="width: 30px;height: 30px">-->
<!--	                </div>-->
                </div>
                <cartMod :isUserInfo="isUserInfo" v-show="activeIndex === 0" @chenkedGoods="chenkedGoods"/>
                <integralCart v-show="activeIndex === 1"/>


            </div>
        </div>

    </div>
</template>

<script>
import cartMod from "@/views/cart/moudles/cartMod.vue";
import integralCart from "@/views/cart/moudles/integralCart.vue";
import {customerGoodsCartPage, orderInfoSave} from "@/api/transaction";
import {userDetail} from "@/api/account";

export default {
    name: "Cart",
    components: {cartMod, integralCart},
    data() {
        return {
            activeIndex: 0, // 购物车或积分框
            cartTotal: 0, // 购物车数量
            cartgoodslist: [], // 购物车商品列表

            isInquiry: false, // 用户是否可询价
            isUserInfo:false, // 用户信息是否完善
        }
    },
    mounted() {

        if (this.$route.query.type === 'jifen') {
            this.activeIndex = 1
        }
        this.getuserDetail() // 查询用户信息是否完善

    },
    methods: {
        // 监听选择的商品
        chenkedGoods(list) {
            this.cartgoodslist = list
            console.log(list)
        },
        // 提示
        message(type, title) {
            this.$message({
                type: type,
                message: title,
                duration: 2000
            });
        },

        // 点击询价
        clickinquiry() {
            let goodsList = []
            let customInfo = JSON.parse(localStorage.getItem('customInfo'))
            this.cartgoodslist.forEach(i => {
                console.log(i)
                if(i.checked){ // 已勾选的
                    let info = {
                        "amount": i.normalPrice * i.count, // 商品金额
                        "goodsId": i.goodsId, // 商品id
                        "quantityPurchase": i.count  // 商品数量
                    }
                    goodsList.push(info)
                }
            })
            if (goodsList.length == 0){
	            this.message('warning', '请先勾选商品')
	            return
            }
            let params = {
                customerId: localStorage.getItem('customId'), // 客户id
                deliveryAddress: customInfo.address, // 工作单位
                deliveryPhone: customInfo.phone, // 电话
                deliveryUsername: customInfo.name, // 姓名
                type: 1, // 0/1 线上订单 线下询价
                "goodsList": goodsList, // 商品信息
            }
            console.log('提交订单参数', params)
            orderInfoSave(params).then((res) => {
                if (res.data.code == 200) {
                    let data = res.data.data
                    console.log(data)
                    this.message('success', '询价成功')
                } else {
                    this.message('error', res.data.message)
                }
            }).catch(error => {
                console.log(error)
                if (error.response.data.code != 200) {
                    this.message('error', error.response.data.message)
                }
            })
        },

        // ---------------- 接口请求
        // 获取用户信息
        getuserDetail() {
            console.log(JSON.parse(localStorage.getItem('userInfo')).userId)
            let userId = JSON.parse(localStorage.getItem('userInfo')).userId
            userDetail({userId: userId}).then((res) => {
                if (res.data.code == 200) {
                    let data = res.data.data
                    if (data.companyName && data.email) { // 工作单位跟邮箱
                        this.isUserInfo = true  // 用户信息完善
                    }
                    this.isInquiry = data.isInquiry == 1 ? true : false
                }

            })
        },

    }
}
</script>

<style scoped>

</style>
