<template>
    <div class="integral-cart">
        <div class="g-table-wrap">
            <table class="g-table">
                <thead>
                <tr>
                    <td>
                        <el-checkbox @change="allCheckedChange" v-model="allChecked"></el-checkbox>
                    </td>
                    <td>商品</td>
                    <td>单价</td>
                    <td>数量</td>
                    <td>小计</td>
                    <td>操作</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,index) in cartgoodsList" :key="index">
                    <td>
                        <el-checkbox @change="CheckedChange" v-model="item.checked"></el-checkbox>
                    </td>
                    <td @click="jumpToDet(item,index)">
                        <div class="det">
                            <div class="pic">
                                <img :src="item.pictureUrl || defaultImg" alt="">
                            </div>
                            <div class="text">
                                <h3>{{ item.name }}</h3>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="red2">{{ item.point }}</div>
                    </td>
                    <td>
                        <div class="tool">
                            <el-input-number v-model="item.count" :min="1" @change="handleChange(item,index)"></el-input-number>
                        </div>
                    </td>
                    <td>
                        <div class="red2">{{ item.totalPoint }}积分</div>
                    </td>
                    <td>
                        <span class="red2 del" @click="showDel = true,goodsid=item.id">删除</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="total">
            已选择 <em>{{totalcount}}</em> 件商品 总价: <em>{{ totalPoint }}</em>积分 (当前积分: <em>{{ customJFtotal }}</em>积分)

            <div @click="settlement" class="g-btn small">去结算</div>
        </div>
        <delPop v-if="showDel" @close="showDel = false" @confirm="Removecartgoods"/>
    </div>
</template>

<script>
import delPop from "@/components/delPop.vue";
import {customerIntegralCartpage,integralCartRemove} from "@/api/Product";

export default {
    name: "integralCart",
    components: {delPop},
    data() {
        return {
            table: {},
            showDel: false,
            customJFtotal:0, // 客户积分总数
            totalPoint:0, // 选中商品总积分
            totalcount:0,
            allChecked:false, // 全选
            cartgoodsList:[], // 积分框商品
            goodsid:"", // 积分商品id
        }
    },
    mounted() {
        this.customJFtotal=JSON.parse(localStorage.getItem('customInfo')).integralTotal
        this.getCartList() // 获取积分框列表
    },
    methods:{
        // 监听数字选择
        handleChange(item,index){
            this.cartgoodsList[index].totalPoint=  Number(item.point) * item.count
            let totalPoint=0
            this.cartgoodsList.forEach(i=>{
                if(i.checked){
                    totalPoint+=i.totalPoint
                }
            })
            this.totalPoint=totalPoint

        },
        // 监听全选
        allCheckedChange(e){
            console.log(e)
            let totalPoint=0
            let totalcount=0
            this.cartgoodsList.forEach(i=>{
                i.checked= this.allChecked
                if(i.checked){
                    totalPoint+=i.totalPoint
                    totalcount+=1
                }

            })
            this.totalPoint=totalPoint
            this.totalcount=totalcount
        },
        // 监听勾选
        CheckedChange(e){
            let allChecked=true
            let totalPoint=0
            let totalcount=0
            this.cartgoodsList.forEach(i=>{
                if(i.checked==false){
                    allChecked=false
                }else{
                    totalPoint+=i.totalPoint
                    totalcount+=1
                }
            })
            this.allChecked=allChecked
            this.totalPoint=totalPoint
            this.totalcount=totalcount
        },
        // 结算
        settlement(){
            let goodsIdlist=[]
            let goodsNums=[]
            this.cartgoodsList.forEach(i=>{
                if(i.checked==true){
                    goodsIdlist.push(i.integralGoodsId)
                    goodsNums.unshift(i.count) // 查询顺序原因
                }
            })
            if(goodsIdlist.length>0){
                if(this.customJFtotal > this.totalPoint){
                    goodsIdlist=goodsIdlist.join(',')
                    goodsNums=goodsNums.join(',')
                    this.$router.push({path:'/IntegralMall/Exchange',query: {goodsIdlist:goodsIdlist,goodsNums:goodsNums}}) //
                }else{
                    this.$message({
                        type: 'warning',
                        message: '积分不足！',
                        duration: 2000
                    });
                }

            }
        },
        // 商品详情
        jumpToDet (item) {
            this.$router.push({path:'/IntegralMall/Detail',query: {id:item.integralGoodsId}}) //
        },

    //    ------------接口请求
    //    获取积分框列表
        getCartList(){
            let parmas={
                customerId:localStorage.getItem('customId'),
                current:1,
                size:100
            }
            customerIntegralCartpage(parmas).then((res) => {
                if (res.data.code==200) {
                    if(res.data.data){
                        let data=res.data.data
                        console.log('积分框列表',res.data.data)
                        data.records.forEach(i=>{
                            i.totalPoint= Number(i.point) * i.count
                            i.checked=false
                        })
                        this.cartgoodsList=data.records
                    }
                }
            })
        },
        // 删除积分框商品
        Removecartgoods(){
            integralCartRemove({ids:this.goodsid}).then((res) => {
                if (res.data.code==200) {
                    this.$message({
                        type: 'success',
                        message: '删除成功',
                        duration: 2000
                    });
                    this.getCartList() // 重新获取购物车列表
                    this.totalPoint=0 // 初始化选中
                    this.totalcount=0
                    this.goodsid=''
                    this.showDel = false
                    this.allChecked=false
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 2000
                    });
                }
            })
        },

    }
}
</script>

<style scoped>

</style>
