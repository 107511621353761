<template>
    <div class="cart-mod">
        <div class="left">

            <div class="g-table-wrap">
                <table class="g-table white-head" v-loading="loading">
                    <thead>
                    <tr>
                        <td>
                            <el-checkbox @change="allCheckedChange" v-model="allChecked"></el-checkbox>
                        </td>
                        <td>产品明细</td>
                        <td>包装规格</td>
                        <td>价格</td>
                        <td>库存</td>
                        <td>数量</td>
                        <td>小计</td>
                    </tr>
                    </thead>
                    <tbody>
                        <tr :class="item.status==0?'undercarriage':''" v-for="(item,index) in cartgoodsList" :key="index">
                            <td>
                                <el-checkbox @change="CheckedChange" v-model="item.checked"></el-checkbox>
                            </td>
                            <td @click="jumpToDet(item,index)">
                                <div class="info">
                                    <p>{{ item.goodsNumber }}</p>
                                    <p class="fwb">{{ item.name }}  {{item.nameEn}}-{{item.molecularFormula}}</p>
                                    <p class="row">
                                                <span class="item">
                                                    <span class="label">品牌    </span>
                                                    <span class="value">{{ item.brandName }}</span>
                                                </span>
                                        <span class="item">
                                                    <span class="label">CAS号    </span>
                                                    <span class="value ftwt">{{ item.casNumber }}</span>
                                                </span>
                                    </p>
                                </div>
                            </td>
                            <td>
                                {{ item.specification }}
                            </td>
                            <td>
	                            <div class="" v-if="item.normalPrice==0"  @click.stop="inquiry(item)">询价</div>
	                            <div class="" v-else>
		                            <div class="old-price">￥{{ item.normalPrice }}</div>
		                            <div style="font-size: 16px">￥{{ item.customerPrice < item.discountPrice ? item.customerPrice: item.customerPrice > item.discountPrice ? item.discountPrice : item.normalPrice }}</div>
	                            </div>

                            </td>
                            <td>
                                <p v-if="item.goodsStockList&&item.goodsStockList.length>0" >
                                    <span v-for="(item1,index1) in item.goodsStockList" :key="index1">{{ item1.stockName }} {{ item1.goodsCount > 50?'>50':item1.goodsCount }}</span>
                                </p>
                                <p v-else>订货</p>
                            </td>
                            <td>
                                <div class="tool">
                                    <el-input-number @change="handleChange(item,index)" v-model="item.count" :min="1"></el-input-number>
                                </div>
                            </td>
                            <td>
	                            <div class="" v-if="item.totalPrice==0"  @click.stop="inquiry(item)">询价</div>
	                            <div v-else>￥{{ item.totalPrice }}</div>

                            </td>
                        </tr>

                    </tbody>
                </table>
                <div v-if="cartgoodsList.length==0" class="text_cen mat20">暂无数据</div>
            </div>
            <div class="clear-row" v-if="cartgoodsList.length>0">
                <div class="g-btn clear" @click="Removecartgoods(0)" style="margin-right: 20px;">
                    删除
                </div>
                <div class="g-btn clear" @click="Removecartgoods(1)">
                    清空购物车
                </div>
            </div>
        </div>
        <div class="right">
            <div class="box">
                <p>目前您选中产品总金额为</p>
                <h3>￥{{ totalPrice }}</h3>
                <span>已选择 {{totalcount}} 件商品    </span>
                <div @click="settlement" class="g-btn">结算</div>
            </div>
            <p class="desc">
                以上金额，仅为产品金额。当单笔金额低于1000元时，提交订单后，系统会在订单里自动添加20元运费。如为易制毒、易制爆产品，会有专业的客服人员和您沟通额外办证费用等。
            </p>
        </div>
    </div>
</template>

<script>
import {customerGoodsCartPage, customerGoodsCartRemove , customerGoodsCartUpdate} from "@/api/transaction";
import {userDetail} from "@/api/account";
import Bus from "@/utils/bus";

export default {
    name: "cartMod",
    props:['cartgoodslist','isUserInfo'],
    data() {
        return {
            table: {},
            allChecked:false,
            cartgoodsList:[],
            loading:true, // 加载中
            totalPrice:0, // 已选商品总金额
            totalcount:0, // 已选商品总数量

            // isuserinfo:false, // 用户信息是否完善
            isInquiry:false, // 用户是否可询价

        }
    },
    mounted() {
        this.getCartList() // 购物车列表
        // this.cartgoodsList=this.cartgoodslist
    },
    methods:{
        // 监听数字选择
        handleChange(item,index){
            let price=item.customerPrice < item.discountPrice ? item.customerPrice: item.customerPrice > item.discountPrice ? item.discountPrice : item.normalPrice
            this.cartgoodsList[index].totalPrice=  Math.round(price * item.count * 100) / 100
            let totalPrice=0
            this.cartgoodsList.forEach(i=>{
                if(i.checked){
                    totalPrice+=i.totalPrice
                }
            })
            this.totalPrice=totalPrice.toFixed(2)
            this.$emit('chenkedGoods',this.cartgoodsList)

            this.customerGoodsCartUpdate(item) // 修改商品数量

        },
        // 监听全选
        allCheckedChange(e){
            console.log(e)
            let totalPrice=0
            let totalcount=0
            this.cartgoodsList.forEach(i=>{
                i.checked= this.allChecked
                if(i.checked){
                    totalPrice+=i.totalPrice
                    totalcount+=1
                }
            })
            this.totalPrice=Math.round(totalPrice * 100) / 100
            this.totalcount=totalcount
            this.$emit('chenkedGoods',this.cartgoodsList)
        },
        // 监听勾选
        CheckedChange(e){
            let allChecked=true
            let totalPrice=0
            let totalcount=0
            this.cartgoodsList.forEach(i=>{
                if(i.checked==false){
                    allChecked=false
                }else{
                    totalPrice+= i.totalPrice
                    totalcount+=1
                }
            })
            this.allChecked=allChecked
	        this.totalPrice=Math.round(totalPrice * 100) / 100
            this.totalcount=totalcount
            this.$emit('chenkedGoods',this.cartgoodsList)
        },
        // 结算
        settlement(){
            if(this.isUserInfo){ // 用户信息完善
                let goodsIdlist=[]
                let goodsNums=[]
                let isxunjia=false
                let isxiajia=false
                this.cartgoodsList.forEach(i=>{
                    if(i.checked==true){
                        goodsIdlist.push(i.goodsId)
                        goodsNums.push({
	                        goodsId:i.goodsId,
	                        count:i.count,
                        }) // 查询顺序的原因 用unshift
                        // if(i.normalPrice==0){ // 有询价商品
                        //     isxunjia=true
                        // }
                        if(i.status==0){ // 有下架商品
                            isxiajia=true
                        }
                    }
                })
                // if(isxunjia){ // 有询价商品
                //     this.$message({
                //         type: 'warning',
                //         message: "勾选商品包含询价商品，不能进行结算！",
                //         duration: 2000
                //     });
                //     return
                // }
                if(isxiajia){ // 有下架商品
                    this.$message({
                        type: 'warning',
                        message: "勾选商品包含下架商品，不能进行结算！",
                        duration: 2000
                    });
                    return
                }
                goodsIdlist=goodsIdlist.join(',')
                goodsNums=encodeURIComponent(JSON.stringify(goodsNums))
                if(goodsIdlist.length>0){
                    this.$router.push({path:'/SettleAccounts',query: {goodsIdlist:goodsIdlist,goodsNums:goodsNums}}) //
                }
            }else{
                this.$message({
                    type: 'warning',
                    message: "请先完善个人信息！",
                    duration: 3000
                });
                this.$router.push({path:'/vip',query:{type:"完善信息"}}) // 去完善个人信息
            }

        },
        // 商品详情
        jumpToDet (item) {
            this.$router.push({path:'/ProductDetail',query: {id:item.goodsId}}) //
        },

        // ---------------- 接口请求

        // 获取购物车列表
        getCartList(){
            let param={
                customerId:localStorage.getItem('customId'),
                current:1,
                size:100
            }
            customerGoodsCartPage(param).then((res) => {
                this.loading=false
                if (res.data.code==200) {
                    console.log('购物车列表',res.data.data)
                    res.data.data.records.forEach(i=>{
                        i.customerPrice=parseFloat(i.customerPrice)
                        i.discountPrice=parseFloat(i.discountPrice)
                        i.normalPrice=parseFloat(i.normalPrice)
                        let price=i.customerPrice < i.discountPrice ? i.customerPrice: i.customerPrice > i.discountPrice ? i.discountPrice : i.normalPrice
                        i.totalPrice= Math.round(price * i.count * 100) / 100
                        i.checked=false
                    })
                    this.cartgoodsList=res.data.data.records
                    this.$emit('chenkedGoods',this.cartgoodsList) // 赋值
                    // this.cartTotal=Number(res.data.data.total)
                }else{
                    // this.$message({
                    //     type: 'error',
                    //     message: res.data.msg,
                    //     duration: 2000
                    // });
                }
            }).catch(err=>{
                this.loading=false
            })
        },
        // 删除购物车商品
        Removecartgoods(type){
            let ids=[]
            if(type==1){ // 清空购物车
                this.cartgoodsList.forEach(i=>{
                    ids.push(i.id)
                })
            }else{ // 勾选删除
                this.cartgoodsList.forEach(i=>{
                    if(i.checked==true){
                        ids.push(i.id)
                    }
                })
            }
            if(ids.length==0) return
            let param={
                // customerId:localStorage.getItem('customId'),
                ids:ids.join(',')
            }
            customerGoodsCartRemove(param).then((res) => {
                if (res.data.code==200) {
                    this.$message({
                        type: 'success',
                        message: type==1?'清除成功':'删除成功',
                        duration: 2000
                    });
                    this.getCartList() // 重新获取购物车列表
                    Bus.$emit('addCart', '删除购物车商品') // 重新获取购物车数量

                    this.totalPrice=0 // 初始化选中
                    this.totalcount=0
                    this.allChecked=false
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 2000
                    });
                }
            })
        },
    //    更新购物车数量
        customerGoodsCartUpdate(row){
            console.log(row)
            let param={
                id:row.id,
                count:row.count,
            }
            customerGoodsCartUpdate(param).then((res) => {
                if (res.data.code==200) {
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 2000
                    });
                }
            })
        },

    },
}
</script>

<style scoped>

</style>
